import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';

import useMedia from '../../../../hooks/useMedia';
import ButtonClick from '../../ButtonClick/ButtonClick';

const SectionContent = ({clients}) => {


  const isBig = useMedia('(max-width: 1399.98px) and (min-width: 991.98px)');
  const isMedium = useMedia('(max-width: 991.98px) and (min-width: 767.98px)');
  const isSmall = useMedia('(max-width: 767.98px)');


  const defaultItems = isBig ? 9 : isMedium ? 3 : isSmall ? 3 : 9;

  const [show, setShow] = React.useState(false);
 

  function showHendler() {
    setShow(!show);
  }

  return (
    <>
      <div className="clients-items">
        {clients.map((item, index) => {
            const image = getImage(item.icon.localFile);
          if (index <= defaultItems) {
            return (
              <div className="clients-item" key={index}>
                <GatsbyImage image={image}
                  className="clients-items-image"
                  alt="client"
                />
              </div>
            );
          } else if (show === true && index >= defaultItems) {
            return (
              <div className="clients-item" key={index}>
                <GatsbyImage image={image}
                  className="clients-items-image"
                  alt="client"
                />
              </div>
            );
          } else return null;
        })}
      </div>
      <div className="clients-footer">
        <span className="clients-footer__total">
          <span className="clients-footer__total-count">{clients.length}</span>
          <span className="clients-footer__total-title">clients in total</span>
        </span>
        <ButtonClick onClick={showHendler} show={show} />
      </div>
    </>
  );
};

SectionContent.propTypes = {
  clients: PropTypes.array,
};

export default SectionContent;
