import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ description, title }) => {
  return (
    <header className="section-header">
      <span className="section-description">{description}</span>
      <h2 className="section-title">{title}</h2>
    </header>
  );
};

SectionHeader.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

export default SectionHeader;
