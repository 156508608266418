import React from 'react';
import PropTypes from 'prop-types';

import SectionHeader from '../SectionHeader/SectionHeader';
import SectionContent from './SectionContent/SectionContent';

const OurClients = ({ clients }) => {
  return (
    <section className="clients" data-scroll-section>
      <div className="content-wrapper">
        <SectionHeader title={clients.title} description={'clients'} />
        <SectionContent clients={clients.icons}/>
      </div>
    </section>
  );
};

OurClients.propTypes = {
  clients: PropTypes.object,
};

export default OurClients;
