import React from 'react';
import PropTypes from 'prop-types';

const ButtonClick = ({ onClick, show }) => {
  return (
    <button
      className={!show ? 'button-click' : 'button-click button-click__active'}
      onClick={onClick}
    >
      <span className="button-click__bg"></span>
      <span className="button-click__title">
        {!show ? 'Show all' : 'Hide all'}
      </span>
      <span className="button-click__icon"></span>
    </button>
  );
};

ButtonClick.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
};

export default ButtonClick;
