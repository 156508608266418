import React from 'react';
import PropTypes from 'prop-types';

const OurNumbers = ({ numbers }) => {

  return (
    <section className="our-numbers" data-scroll-section>
      <div className="content-wrapper">
        <span className="section-description">Our numbers</span>
        <div className="our-numbers__content">
          {numbers.map((item, index) => {
            return (
              <div
                className="our-numbers__content-item"
                key={index}
                data-scroll=""
                data-scroll-speed={'0.5' + index}
                data-scroll-delay="0.05"
              >
                <div className="our-numbers__content-item__counter">
                  <span className="our-numbers__count our-numbers__count-top">
                    {item.value}
                  </span>
                  <span className="our-numbers__count our-numbers__count-middle">
                    {item.value}
                  </span>
                  <span className="our-numbers__count our-numbers__count-bottom">
                    {item.value}
                  </span>
                </div>
                <p className="our-numbers__content-item__description">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

OurNumbers.propTypes = {
  numbers: PropTypes.array,
};

export default OurNumbers;
