import React from 'react';
import PropTypes from 'prop-types';

import ContentCenter from './ContentCenter/ContentCenter';
import BottomLine from './BottomLine/BottomLine';
// import VideoModal from '../VideoModal/VideoModal';

const MainScreenFirst = ({ socials, title, video, poster }) => {
  // const [open, setOpen] = React.useState(false);
  // const [play, setPlay] = React.useState(false);
  
  // function playReel() {
  //   setOpen(true);
  //   setTimeout(() => {
  //     setPlay((play) => true);
  //   }, 3000);
  // }

  // function stopReel() {
  //   setOpen(false);
  //   setPlay(false);
  // }

  return (
    <>
      <section className="main-screen" id="top-section" data-scroll-section>
        <div className="main-screen__background">
          <video
            reload="auto"
            poster={`https://admin.zerotabletop.com${poster?.url}`}
            className="main-screen__background-video"
            loading="lazy" 
            async=""
            playsInline={true}
            webkit-playsinline=""
            autoPlay={true}
            muted={true}
            loop={true}
            data-scroll=""
            data-scroll-speed="-1.5"
            full-width="true"
            cover-absolute="true"
          >
            <source type="video/mp4" src={`https://admin.zerotabletop.com${video?.url}`}></source>
          </video>
        </div>
        <div className="page-wrapper">
          {/* <ContentCenter title={title} playReel={playReel} /> */}
          <ContentCenter title={title}/>
          <BottomLine socials={socials} />
        </div>
      </section>
      {/* <VideoModal
        stopReel={stopReel}
        play={play}
        open={open}
        showreel={showreel}
      /> */}
    </>
  );
};

MainScreenFirst.propTypes = {
  socials: PropTypes.array,
  title: PropTypes.string,
  showreel: PropTypes.string,
  video: PropTypes.object,
  poster: PropTypes.object,
};

export default MainScreenFirst;
