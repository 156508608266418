import React from 'react';
import PropTypes from 'prop-types';

// import PlayReelXl from '../../../../svg/play-show-xl.svg';
// import PlayReelSm from '../../../../svg/play-real-sm.svg';

const ContentCenter = ({ title }) => {
  return (
    <>
      <div className="content-center">
        <h1 className="h1">{title}</h1>
        {/* <button className="play-showreel" onClick={playReel}>
          <PlayReelXl className="play-showreel__icon-xl" />
          <PlayReelSm className="play-showreel__icon-sm" />
          <span className="play-showreel__title">Play showreel</span>
        </button> */}
      </div>
    </>
  );
};

ContentCenter.propTypes = {
  title: PropTypes.string,
  showreel: PropTypes.string,
  playReel: PropTypes.func,
};

export default ContentCenter;
