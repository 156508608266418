import React from 'react';
import PropTypes from 'prop-types';
// import { scroller } from 'react-scroll';

import ArrowRight from '../../../../svg/arrow-top-right.svg';
import ArrowDown from '../../../../svg/arrow-down-right.svg';

const BottomLine = ({ socials }) => {
  // function scrollDown() {
  //   scroller.scrollTo('second-section', {
  //     duration: 500,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //   });
  // }

  return (
    <div className="main-screen__bottom">
      <a href="#second-section" className="scroll-down" data-scroll-to>
        <span className="scroll-down__title">Scroll Down</span>
        <span className="scroll-down__icon">
          <ArrowDown />
        </span>
      </a>
      <ul className="main-socials">
        {socials.map((item, index) => {
          return (
            <li className="main-socials__item" key={index}>
              <a
                href={item.link}
                className="main-socials__item-link"
                target="blank"
              >
                <span className="main-socials__item-title">{item.title}</span>
                <span className="main-socials__item-icon">
                  <ArrowRight />
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

BottomLine.propTypes = {
  socials: PropTypes.array,
};

export default BottomLine;
